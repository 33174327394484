@use '../../../../../../goldstar-share/src/app/style/style.scss' as index;

/* ===== TOP NAVIGATION BAR ===== */
.top-navbar {
	/* === BACKGROUND TRANSPARENT ======*/
	@include index.transparentBackground();
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-left: 0.5rem;

	& .top-navbar-item-container {
		margin-top: -1px;
	}

	.arrow-right {
		width: 0;
		height: 0;
		border-top: 30px solid transparent;
		border-bottom: 26px solid transparent;
		border-left: 23px solid #f2dcb5;
		margin-left: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
		@include index.transition-medium(all, ease);
		@include index.hover(transparent);
	}

	/* ======== Side Bar Toggler */
	.toggle {
		position: absolute;
		left: 18px;
		color: index.$internal-app-primary-color;
	}

	.toggle-padded {
		position: absolute;
		left: 113px;
		color: index.$internal-app-primary-color;
	}

	& .dashboard-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}

	ol,ul{
		padding-left: 0 rem !important
	}

	&>li:last-child {
		margin-right: 4rem;
	}

	/* ======= SEARCH CONTAINER ======= */
	& .search-list-item {
		display: flex;
		justify-content: center;
		flex-grow: 1;

		/* ==== FORM CONTROL ===== */
		& .search-container {
			display: flex;
			flex-direction: row;
			flex-grow: 1;
			justify-content: center;
			align-items: center;

			/* ===== SEARCH INPUT ======= */
			& .search {
				// width: 30%;
				color: inherit;
				font-family: inherit;
				font-size: inherit;
				border: 0.8px solid lightgrey;
				border-radius: 10px;
				padding: 0.3rem;
				transition: all 0.2s;
				padding-left: 10px;

				// This is to bring the search icon within the search container
				margin-right: -1.5rem;
			}

			& .search-icon {
				color: index.$internal-app-primary-color;
				align-self: center;
				justify-self: center;
			}
		}
	}
}

.expanded-height {
	height: 3.49rem;
}

.collapsed-height {
	height: 3.19rem;
}

/* =====  LIST RE-USABLE STYLE */
ul {
	list-style: none;

	& li {
		margin-top: 10px;
	}
}

/* =====  ANCHOR RE-USABLE STYLE */
a {
	text-decoration: none;
	color: black;
}

.right-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	& .right-items {
		padding: 0px 10px 0px 10px;
		align-items: stretch;
		align-items: center;
		justify-content: center;
		border: 1px solid index.$internal-app-primary-color;
		background-color: index.$internal-app-primary-color;
		border-radius: 22%;
		height: 30px;
		width: 35px;
		margin-right: 10px;
		@include index.hover();

		& .icon {
			margin-top: 2px;
			color: index.$internal-app-white;
		}
	}

	/* ======= PROFILE DROPDOWN BUTTONS ===== */
	& .profile-dropdown {

		/* ==== DROPDOWN BUTTON */
		& .app-dropdown {
			background-color: index.$internal-app-primary-extra-light !important;
			border: index.$internal-app-primary-extra-light !important;

			& .profile-name {
				color: index.$internal-app-primary-color !important;
				font-weight: 600;
				vertical-align: middle !important;
			}

			&::after {
				color: index.$internal-app-primary-color !important;
				vertical-align: middle !important;
			}
		}

		/* =====  DROPDOWN MENU ICON */
		& .menu {
			background-color: index.$internal-app-primary-color;
			list-style: none;

			& .item-container {
				display: flex;
				flex-direction: row;

				& a {
					align-self: stretch;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					transition: all 0.3s ease;

					&:focus,
					&:hover {
						background-color: #13274f !important;
						cursor: pointer;

						&>span {
							font-weight: 700;
						}
					}

					&>span {
						color: white;
						padding-left: 10px;
					}
				}
			}

			& .profile-icon {
				color: index.$internal-app-aqua;
			}
		}
	}

	/* ======= HAMBURGER DROPDOWN BUTTONS ===== */
	& .hamburger-menu-container {
		& .app-dropdown {
			background-color: index.$internal-app-transparent !important;
			border: index.$internal-app-transparent !important;

			& .profile-name {
				color: index.$internal-app-primary-color !important;
				font-weight: 600;
			}

			&::after {
				color: index.$internal-app-primary-color !important;
			}
		}

		/* =====  DROPDOWN MENU ICON */
		& .menu {
			background-color: index.$internal-app-primary-color;
			list-style: none;

			& .item-container {
				display: flex;
				flex-direction: row;

				& a {
					align-self: stretch;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					transition: all 0.3s ease;

					&:focus,
					&:hover {
						background-color: #13274f !important;
						cursor: pointer;

						&>span {
							font-weight: 700;
						}
					}

					&>span {
						color: white;
						padding-left: 10px;
					}
				}
			}

			& .profile-icon {
				color: index.$internal-app-aqua;
			}
		}
	}
}

.main-container {
	& .user-history-container {
		@media (min-width: 1000px) {
			max-height: 240px;
		}
	}
}

/* ========== MEDIA QUERIES ================ */
@media screen and (max-width: 1201px) {
	.main-container {
		& .user-history-container {
			height: 100%;
			flex-grow: 3;
			display: flex;
			padding-bottom: 1rem;
			//flex-direction: column;
			//margin-left: 2rem;
			//margin-right: 2rem;

			& .to-do-list-container {
				width: 100%;
			}

			& .user-history-content-container {
				height: 100%;
				flex-grow: 1;
				margin-top: 2rem;
			}
		}
	}
}

@media screen and (min-width: 1202px) {
	.main-container {
		& .user-history-container {
			height: 100%;
			flex-grow: 3;
			display: flex;
			padding-bottom: 1rem;
			//margin-left: 2rem;
			//margin-right: 2rem;

			& .to-do-list-container {
				width: 100%;
			}

			& .user-history-content-container {
				height: 100%;
				flex-grow: 1;
				margin-left: 2rem;
			}
		}
	}
}

@media screen and (max-width: 1025px) {
	.main-container {
		& .right-container {
			& .right-items {
				// display: none;
				display: flex;
			}

			& .hamburger-menu-container {
				display: inline-block;
			}
		}

		& .top-navbar .search-list-item .search-container .search {
			width: 60%;

			&:focus {
				width: 70%;
				outline: none;
				border: 1px solid index.$internal-app-primary-color;
				background-color: index.$internal-app-grey-medium;
			}
		}
	}
}

@media screen and (min-width: 1026px) {
	.main-container {
		& .right-container {
			& .right-items {
				display: flex;
			}

			& .hamburger-menu-container {
				display: none;
			}
		}
	}
}

@media screen and (min-width: 1206px) {
	.main-container {
		& .right-container {
			& .right-items {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		& .main-container {
			& .top-navbar .search-list-item .search-container .search {
				width: 30%;

				&:focus {
					width: 40%;
					outline: none;
					border: 1px solid index.$internal-app-primary-color;
					background-color: index.$internal-app-grey-medium;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.main-container {
		& .main-container {
			& .top-navbar .header-name {
				display: none;
				font-size: 20px;
				color: index.$internal-app-primary-color;
				font-weight: 500;
				margin-left: 22px;
				margin-top: 5px;
			}
		}
	}
}

@media screen and (min-width: 769px) {
	.main-container {
		& .main-container {
			& .top-navbar .header-name {
				display: inline-block;
				font-size: 20px;
				color: index.$internal-app-primary-color;
				font-weight: 500;
				margin-left: 22px;
				margin-top: 5px;
			}
		}
	}
}

.suggestion-box {
	position: relative;
}

.suggestion-box::after {
	content: "";
	width: 10px;
	height: 10px;
	background-color: red;
	border-radius: 50%;
	position: absolute;
	top: -4px;
	right: -4px;
}